import Footer from '../core/footer'
import GlobalStyles from '../core/global-styles'
import { graphql } from 'gatsby'
import Header from '../core/header'
import { ImageData } from '../craft-cms/image'
import mistergreenConfig from '../../../mistergreen-config'
import { MisterGreenConfig } from '../../utilities/general/types'
import PageWrapper from '../ui/page-wrapper'
import React from 'react'
import Section from '../core/section'
import SEO from '../core/seo'

const { allLocales, locales }: MisterGreenConfig = mistergreenConfig

interface LocaleCard {
    image: ImageData[]
    alt: string
    heading: string
    text: string
    linkText: string
    linkTo: string
}

interface LocaleSelectionPageData {
    localeHeading: string
    localeCards: LocaleCard[]
}

interface LocaleSelectionSEOData {
    localesTitle: string
    localesDescription: string
    defaultAuthor: string
}

interface LocaleSelectionData {
    craftLocaleSelectionPageGlobalSet: LocaleSelectionPageData
    craftSeoGlobalSet: LocaleSelectionSEOData
}

interface LocaleSelectionPageProps {
    data: LocaleSelectionData
    pageContext: {
        environment: string
        localeSiteIds: number[]
    }
}

const LocaleSelectionPage = ({ data, pageContext }: LocaleSelectionPageProps): JSX.Element => {
    const { localesTitle, localesDescription, defaultAuthor } = data.craftSeoGlobalSet
    const { localeHeading, localeCards } = data.craftLocaleSelectionPageGlobalSet

    const localeCardsData = {
        background: 'defaultBackground',
        optionalHeading: localeHeading,
        children: localeCards.map((card: LocaleCard) => ({
            remoteTypeName: 'linkCard',
            linkCard: [
                {
                    image: card.image,
                    alt: card.alt,
                    heading: card.heading,
                    text: card.text,
                    link: {
                        slug: `/${
                            allLocales.find(locale => locale.site.toLowerCase() === card.linkTo.toLowerCase())?.language
                        }/`,
                        text: card.linkText,
                    },
                },
            ],
        })),
    }

    const localeLinks = locales.map((locale, index) => {
        return {
            id: index,
            isCurrent: '',
            language: locale.language,
            languagePrettyName: locale.languagePrettyName,
            slug: `/${locale.language}/`,
        }
    })

    return (
        <>
            <SEO
                author={defaultAuthor}
                title={localesTitle}
                description={localesDescription}
                environment={pageContext.environment}
            />
            <GlobalStyles />
            <PageWrapper>
                <Header
                    localeLinks={localeLinks}
                    isFullNavBar={false}
                    isNavBarSeeThrough={false}
                    renderShoppingCart={false}
                />
                <main>
                    <Section
                        blogFeed={[]}
                        data={localeCardsData}
                        hideHeadingOnMobile={true}
                        minimalOnMobile={true}
                        sectionIndex={0}
                    />
                </main>
                <Footer localeLinks={localeLinks} contactDetails={[]} />
            </PageWrapper>
        </>
    )
}

export default LocaleSelectionPage

export const query = graphql`
    query getCraftLocaleSelectionPage($localeSiteIds: [Int]) {
        craftSeoGlobalSet(siteId: { in: $localeSiteIds }) {
            id
            localesTitle
            localesDescription
            defaultAuthor
        }
        craftLocaleSelectionPageGlobalSet(siteId: { in: $localeSiteIds }) {
            id
            localeHeading
            localeCards {
                ... on Craft_localeCards_BlockType {
                    image {
                        filename
                    }
                    alt
                    heading
                    text
                    linkText
                    linkTo
                }
            }
        }
    }
`
